<template>
	<div style="display: flex;flex-wrap:wrap;">
    <el-upload
        action="#"
        list-type="picture-card"
        :http-request="uploadAvatar"
        accept="image/jpeg,image/jpg,image/png"
        :multiple="true"
        :limit="9"
        :file-list="fileList"
        :show-file-list="showFileList"
        :before-upload="beforeAvatarUpload">
      <!--              :show-file-list="false"-->
      <i class="el-icon-plus"></i>
    </el-upload>
    <div class="appraiseList" v-if="isList">
      <div class="imgBox" v-for="(item,index) in fileList2 " :key="index">
        <div class="delete">
          <i class="el-icon-close" @click="deleteImg"></i>
        </div>
        <sb-image :src="item.url" :src-list="[item.url]" width="50px" height="50px" ></sb-image>
      </div>
    </div>

	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	/**
	 * handleSuccess {}    上传后钩子
	 * beforeAvatarUpload 上传前钩子
	 * imageUrl 回显图片路径
	 **/
	import {UPLOADiMG} from '../../api/base'
  import SbImage from '../../components/image/image'
	export default {
		data() {
			return {
        fileList2:[],
				imageUrlCopy: undefined,
				uploadTypeList: {
				},
			}
		},
		props: {
      //
      showFileList:{
        default:()=>{
          return false;
        }
      },
			uploadUrl:{
				default:()=>{
					return process.env.VUE_APP_API_BASE_URL + '' + UPLOADiMG;
				}
			},
      isList:{
        default:()=>{
          return false;
        }
      },
			type: {},
			imgStyle: {
				default: () => {
					return {}
				}
			},
			imageUrl: {
				default: () => {
					return undefined
				}
			},
      fileList:{
        type:[Array]
      }
		},
		watch: {
      showFileList:{
        handler(newVal){
          if(newVal){
            this.isList = false;
          }
        }
      },
      fileList:{
        handler(newVal){
          if(newVal){
            this.fileList2 = newVal
          }
        },
        immediate:true,
      },
      imgStyle:{

      },
			imageUrl: {
				handler(newVal) {
          console.log(newVal)
					if (newVal && newVal.indexOf('http') == -1) {
						this.imageUrlCopy = process.env.VUE_APP_FILE_KEY + '/' + newVal
					} else {
						this.imageUrlCopy = newVal || undefined
					}
				},
				immediate: true,
			}
		},
		methods: {
      deleteImg(index){
        this.fileList2.splice(index,1)
        this.$emit('remore',this.fileList2)
      },
      clear(){
        this.fileList2 = [];
      },
      uploadAvatar(file){
        const form = new FormData()
        form.append('name',file.file)
        form.append('nameSpaces',this.uploadTypeList[this.type])
        this.$axios.form(UPLOADiMG,form).then(resp=>{
          console.log(resp)
          if(resp.code == 200){
            const map = {
              url:resp.data.path,
              hash:resp.data.hash,
              status:'success',
              name:new Date().getTime()
            }
            this.fileList2.push(map)
            this.$emit('success',this.fileList2)
          }else{
            this.$mes({type:'warning',message:resp.msg})
          }
        }).catch(()=>{
          this.fileList = []
          this.fileList2 = []
          this.$mes({type:'warning',message:'上传失败'})
        })
      },
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
				// const isLt2M = file.size / 1024 / 1024 < 4;

				if (!isJPG) {
					this.$mes({message: '上传图片只能是 JPG，JPEG，PNG 格式!', type: 'error'})
				}
				// if (!isLt2M) {
				// 	this.$mes({message: '上传图片大小不能超过 4MB!', type: 'error'})
				// }
				// return isJPG && isLt2M;
				return isJPG;
			}
		},
		components: {
      SbImage,
		},
		mounted() {
			this.uploadTypeList = this.$store.state.uploadTypeList;
		},


	}
</script>

<style lang="less" scoped>
/deep/ .el-upload--picture-card{
  width: 80px !important;
  height: 80px !important;
  line-height:  80px !important;
}
  .appraiseList{
    display: flex;
    flex-wrap: wrap;
    .imgBox{
      margin-bottom: 10px;
      position: relative;
      border-radius: 6px;
      width: 80px;
      height: 80px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background:  rgba(245,245,245,1);
      .delete{
        cursor: pointer;
        position: absolute;
        right: -4px;
        top: 4px;
        z-index: 44;
        font-size: 14px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        line-height: 14px;
      }
    }
    .border{
      border: 1px dashed #999;
    }
  }
	.no-upload{
		position: relative;
		border: 2px dashed rgb(221, 221, 221);
		border-radius: 6px;
		cursor: pointer;
    line-height: 100%;
		overflow: hidden;
		display: inline-block;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.no-upload {
		font-size: 28px;
		color: rgb(221, 221, 221);
		width: 93px;
		height: 93px;
		line-height: 93px;
		text-align: center;
	}

	.avatar {
		display: block;
	}

	.icon-B {
		color: rgb(221, 221, 221);
		font-size: 40px;
	}
</style>
