<template>
		<el-image :src="srcCopy" :preview-src-list="srcList" class="imgs" :style="{width,height}">
			<div slot="error" class="image-slot imgbox">
				<!-- <div class="image-slot"> -->
				<i class="iconfont icon-tupianjiazaishibai"></i>
				<!-- </div> -->
			</div>
		</el-image>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。

	export default {
		data() {
			return {
				srcCopy: '',
			}
		},
		props: {
			width: {},
			height: {},
			imgStyle: {
				default: () => {
					return ''
				}
			},

			srcList: {
				default: () => {
					return []
				}
			},
			src: {
				type: String,
				default: () => {
					return ''
				}
			}
		},
		watch: {
			src: {
				handler(newVal) {
					this.srcCopy = this.imgHandle(newVal)
				},
				immediate: true,
			},
			srcList: {
				handler(newVal) {
          if(newVal){
            newVal.forEach((item, index) => {
              this.srcList[index] = this.imgHandle(item)
            })
          }

				},
				immediate: true,

			},
		},
		methods: {
			imgHandle(url) {
        if(!url)return
				if (url.indexOf('http') !== -1) {
					return url
				} else {
					return process.env.VUE_APP_FILE_KEY + '/' + url;
					// return 'http://saas.i.aiopc.cn' + '/' + url;

				}

			}
		},
		components: {},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.imgbox {
		width: 100%;
		height: 100%;
		background-color: rgba(220, 220, 220, 0.4);
		font-size: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.imgs {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;

	}
</style>
