<template>
  <div>
    <el-upload
        action="#"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :limit="1"
        accept="video/mp4"
        :http-request="uploadAvatar"
        :on-exceed="handleExceed"
        :file-list="fileList">
      <el-button size="small" type="primary" :loading="loading">点击上传视频</el-button>
      <div slot="tip" class="el-upload__tip">只能上传mp4文件</div>
    </el-upload>
  </div>
</template>

<script>
import {UPLOADVIDEO} from "@/api/base";

export default {
  name: "uploadVideo",
  data(){
    return{
      loading:false,
      uploadTypeList:{},
      fileList: [

      ]
    }
  },
  props:{
    type:{}
  },
  mounted() {
    this.uploadTypeList = this.$store.state.uploadTypeList;
  },
  methods:{
    clear(){
      this.fileList = []
    },
    uploadAvatar(file){
      const form = new FormData()
      form.append('name',file.file)
      form.append('nameSpaces',this.uploadTypeList[this.type])
      this.loading = true;
      this.$axios.form(UPLOADVIDEO,form).then(resp=>{
        console.log(resp)
        this.loading = false;
        if(resp.code == 200){
          const map = {
            url:resp.data.path,
            hash:resp.data.hash,
            status:'success',
            name:new Date().getTime()
          }
          this.$mes({message:resp.msg})
          this.$emit('success',[map])
        }else{
          this.$mes({type:'warning',message:resp.msg})
          this.fileList =[]
        }
      }).catch(()=>{
        this.$mes({type:'warning',message:'上传失败'})
        this.loading = false;
      })
    },
    handlePreview(){

    },
    handleRemove(){

    },
    handleExceed(){
      this.$mes({type:'warning',message:'最多上传一个视频'})
    },

  }
}
</script>

<style scoped>

</style>