

let ACCOUNTLIST = '/seller/top/up/account';


let SHOP = '/shop';
let SHOPSELECTEX= '/shop/expand/select';

let GOODS = '/goods';
let GOODSLOGIC = '/goods/logic';
let GOODSTATUS = '/goods/status';
let GOODSINFOLIST = '/goods/baby/info';


let NOTICE = '/notice/top';

let RECHARGELIST = '/seller/recharge'
let RECHARGE = '/seller/recharge/pay'
let USERINFO = '/seller/center'
//任务
let TASK = '/task';
let COPY = '/task/copy';
let PUSH = '/task/publish';
let LOGIC = '/task/logic/';
let EDITTASK = '/task/get';

//订单详情
let ORDERLIST = '/order';
let CATEGORY = '/category/select';
let PLATFORM = '/platform/select';

//优惠卷分组
let COUPONGROUP = '/shop/coupon/group';
let COUPONGROUPLOGIC = '/shop/coupon/group/logic';
//优惠卷
let COUPON = '/shop/coupon';
let COUPONLOGIC = '/shop/coupon/logic';
//电商课堂
let FACULTY = '/faculty';
let FACULTYCOLUMN = '/faculty/column';
let FACULTYDETAILS = '/faculty/details';

//基础
let LOGIN = '/login';
let LOGOUT = '/logout';
let REGISTER = '/register';
let AREA = '/area';
let UPLOADiMG = '/upload/image';
let UPLOADVIDEO = '/upload/video';

let UPLOADWATERiMG = '/upload/image/water';

let SMS = '/send/sms';
let UPDATEPWD = '/seller/update/pwd';

//下拉
let SHOPSELECT = '/shop/select';
let SHOPEXPAND = '/shop/expand/select';
let DICTSELECT = '/dict/select';
let PLATFORMSELECT = '/platform/select';
let TASKENTRANCE = '/task/entrance/select';
let COUPONSELECT = '/shop/coupon/group/select';

//任务
let SITUATION = '/task/situation';
let PROGRESS = '/task/progress/today';
let TASKTYPE = '/task/type/select';
let TASKRULES = '/task/rule/config';

//任务账单
let TASKBILL = '/finance/task/bill';
let BILLINFO = '/finance/task/bill/details';

//资金明细
let BILL = '/seller/amount/record';
//评价
let APPRAISE = '/order/appraise';
let AUDITAPPRAISE = '/order/appraise/audit';
let BATCHAPPRAISE = '/order/appraise/batch';
let IMPORTAPPRAISE = '/order/appraise/import';
let DELETEAPPRAISE = '/order/appraise/logic';
let EDITAPPRAISEPRICE = '/order/appraise/modify/price';
let APPRAISEDOWNLOAD = '/download/appraise';



//图片路径
let ImgUrl = process.env.VUE_APP_FILE_URL+'/';




export {
    APPRAISE,AUDITAPPRAISE,BATCHAPPRAISE,IMPORTAPPRAISE,EDITAPPRAISEPRICE,DELETEAPPRAISE,APPRAISEDOWNLOAD,
    TASK,COPY,PUSH,LOGIC,EDITTASK,
    TASKBILL,BILLINFO,BILL,
    SHOPEXPAND,
    COUPONSELECT,
    UPLOADWATERiMG,
    DICTSELECT,
    TASKENTRANCE,
    ORDERLIST,
    RECHARGE,
    SITUATION,
    TASKRULES,
    ImgUrl,UPLOADVIDEO,
    TASKTYPE,
    PROGRESS,
    SMS,
    SHOPSELECT,
    PLATFORMSELECT,
    SHOPSELECTEX,
    UPDATEPWD,
    FACULTY,
    FACULTYCOLUMN,
    FACULTYDETAILS,

    COUPON,
    COUPONLOGIC,
    COUPONGROUP,
    COUPONGROUPLOGIC,
    USERINFO,
    PLATFORM,
    LOGIN,
    REGISTER,
    LOGOUT,
    SHOP,
    AREA,
    UPLOADiMG,
    GOODS,
    GOODSLOGIC,
    GOODSTATUS,
    RECHARGELIST,
    CATEGORY,
    ACCOUNTLIST,
    NOTICE,
    GOODSINFOLIST,
    // NOTICE,



}