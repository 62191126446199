
/*
* 验证手机号码
* @return Object
* */
export function validatePhone(rule, PhoneNum, callback) {
    PhoneNum = Number(PhoneNum)
    let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (PhoneNum == '') {
        return callback(new Error('手机号码不能为空'));
    } else if (!myreg.test(PhoneNum)) {
        return callback(new Error('请输入正确的手机号码'));
    }
    return callback();
}
/*
* 验证价格
* @return Object
* */
export function price(rule, price, callback) {
    if (price === '') {
        return callback(new Error('金额不能为空'));
    } else if (!/^[0-9]+([.]\d{1,2})?$/.test(price)) {
        return callback(new Error('请输入有效的金额'));
    }
    return callback();
}
/*
* 非空判断
* @parmas mes 为空的信息
* @return Object
* */
export function dateEmpty(mes) {
    return function (rule, empty, callback) {
        if (empty == '') {
            return callback(new Error(mes + '不能为空'));
        }
        return callback();
    }
}


/*
* 验证整数
* @return Object "^[0-9]*[1-9][0-9]*$"
* */
export function isInt(rule, isInt, callback) {
    if (price === '') {
        return callback(new Error('请输入整数'));
    } else if (!(/(^[0-9]*[1-9][0-9]*$)/.test(isInt))) {
        return callback(new Error('请输入整数'));
    }
    return callback();
}

export default {
    validatePhone,
    dateEmpty,
};