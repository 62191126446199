<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false" width="1100px">
      <el-form ref="form" :model="form" label-width="130px" :rules="formRules" size="medium">

        <el-form-item label="评语："  prop="content">
          <el-input v-model="form.content" placeholder="请输入评语" type="textarea"
                    :rows="2" style="width: 500px;">
          </el-input>
        </el-form-item>

        <el-form-item label="评价图片："  prop="picture">
         <upload-list type="10" ref="uploadList" :isList="true" @success="successUploadPrice" @remore="remore"></upload-list>
        </el-form-item>

        <el-form-item label="评价视频："  prop="videoUrl">
          <upload-video type="11" ref="uploadVideo" @success="successUploadVideo" @remore="remore" >
          </upload-video>
        </el-form-item>

        <el-form-item label="评价费用："  prop="price">
          <el-input-number v-model="form.price" :min="form.videoUrl?3:2"  :step="0.5" label="请输入评价费用" style="width: 160px"></el-input-number>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { price} from "../../../tools/validate";
import UploadList  from '../../../components/uploadList/uploadList';
import uploadVideo  from '../../../components/uploadVideo/uploadVideo';

import {APPRAISE} from "../../../api/base";

export default {
  name: "addAppraise",
  data(){
    return {
      dialogVisible:false,
      title:'新增评价',
      priceList:[],
      form:{
        content:'',
        picture:'',
        price:'',
        videoUrl:'',
      },
      formRules:{
        price:[
          {required:true,validator:price,trigger:['blur']},
        ],
        content:[
          {required:true,message:'请输入评语',trigger:['blur']},
        ],


  },
    }
  },
  components:{
    UploadList,
    uploadVideo,
  },
  methods:{
    remore(fileList){
      if(fileList.length == 0){
        this.form.picture = ''
      }else {
        this.setPrice(fileList)
      }
    },
    setPrice(fileList){
      this.priceList = fileList;
      let arr = []
      fileList.forEach(item=>{
        arr.push(item.url)
      })
      console.log(arr)
      this.form.picture =arr.join(',')
    },
    successUploadVideo(video){
        this.form.videoUrl = video[0].url
        if(this.form.price<3){
          this.form.price = 3
        }
    },
    successUploadPrice(fileList){

      this.setPrice(fileList)
    },
    submit(){
      if(this.title.includes('买家旺旺')){
        this.$refs.form.validate((valitor=>{
          if(valitor){
            if(this.$refs.uploadVideo.loading){
              this.$mes({type:'warning',message:'视频未上传成功，请稍等'})
              return
            }
            this.$axios.post(APPRAISE,this.form).then(resp=>{
              if(resp.code == 200){
                this.dialogVisible = false;
                this.$mes({message:resp.msg})
                this.$emit('success')
              }
            })
          }
        }))
      }else{
        this.$refs.form.validate((valitor=>{
          if(valitor){
            if(this.$refs.uploadVideo.loading){
              this.$mes({type:'warning',message:'视频未上传成功，请稍等'})
              return
            }
            this.dialogVisible = false;
            this.form['id'] = new Date().getTime()
            this.$emit('add', {...this.form})
          }
        }))

      }
    },
    handleClose(){
      if(this.$refs.uploadVideo.loading){
        this.$mes({type:'warning',message:'视频未上传成功，请稍等'})
        return
      }
      this.$refs.uploadList?.clear()
      this.$refs.uploadVideo?.clear();
      this.$refs.form?.resetFields()
      setTimeout(()=>{
        this.dialogVisible = false;
      })
    },
    open(row={}){
      this.dialogVisible = true;
      if(row.accountName){
        this.title ='买家旺旺：'+row.accountName;
        this.form.orderId = row.id;
      }
      this.$refs.form?.resetFields()
      this.$refs.uploadList?.clear();
      this.$refs.uploadVideo?.clear();

    }
  }
}
</script>

<style scoped>

</style>